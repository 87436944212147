import React from 'react';
import './LandingPage.css'; // Import the CSS file for styling

const LandingPage = () => {
  return (
    <div className="container">
      <h1 className="main-title">Dream Atlas</h1>
      {/* <h1 className="subtitle">Unlock the Meaning of Your Dreams</h1> */}
      <p className="tagline">Map the Meaning of Your Dreams With our AI Telegram Bot</p>
      <a href="https://t.me/dreamatlas_bot" className="cta-button" target="_blank" rel="noopener noreferrer">Start Chatting Now</a>
      <p className="text-block">
        Receive in-depth dream readings and personalized insights rooted in Jungian psychology.
      </p>
    </div>
  );
};

export default LandingPage;
